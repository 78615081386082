import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppInitOptionsService from '../../../services/appInitOptionsService';
import Picture from '../../../components/Picture';

interface Props {
  showHeading?: boolean;
  classes?: string;
}

const PressBanner: React.FC<Props> = ({ classes, showHeading = true }) => {
  const { t } = useTranslation();
  const isApp = AppInitOptionsService.getInstance().isApp();

  useEffect(() => {
    if (isApp) {
      const links = document.querySelectorAll('#press-banner > a');

      if (links.length > 0) {
        links.forEach((link) => {
          link.removeAttribute('target');
          link.setAttribute('href', 'javascript: void(0)');
        });
      }
    }
  }, [isApp]);

  return (
    <div className={`${classes}`}>
      {showHeading && (
        <h2 className="text-2xl font-semibold">{t('general.knownFrom')}</h2>
      )}
      <div className="flex items-center justify-center">
        <div
          className="flex justify-start overflow-x-auto align-center"
          id="press-banner"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.puls4.com/2-minuten-2-millionen/staffel-7/videos/Beitraege/Getsby-im-Pitch-805619"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/puls4-logo.svg'
              }
              width={80}
              altTag="getsby im Pitch"
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.trendingtopics.at/?s=getsby"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/TrendingTopics-c.png'
              }
              width={80}
              altTag={`getsby bei TrendingTopics`}
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://futurezone.at/start-ups/getsby-ist-austrias-next-top-start-up-2018/400149156"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/AustriasNextTopStartup2018.png'
              }
              width={80}
              altTag="getsby ist Austria's Next Top Start-up 2018"
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.trendingtopics.at/getsby-glaubandich-wien-2019/"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/GlaubAnDich2019Finalist.png'
              }
              width={80}
              altTag="Das FinTech getsby zieht ins Finale der #glaubandich-Challenge
            ein"
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://oe3.orf.at/stories/2989541/"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/Hitradio_Oe3c.png'
              }
              width={80}
              altTag="Mit Gratis-App im Lokal bestellen und zahlen"
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://lebensmittelpraxis.de/industrie-aktuell/22329-food-start-up-2018-die-besten-food-start-ups-2018-stehen-fest-2018-09-19-09-18-43.html"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/FoodStartup2018.png'
              }
              width={80}
              altTag="Die besten Food Start-ups 2018 stehen fest"
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.prosieben.at/tv/galileo/videos/224-nie-mehr-auf-den-kellner-warten-eine-neue-app-macht-s-moeglich-clip"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/ProSieben_Logo_final_c.png'
              }
              width={80}
              altTag="Nie mehr auf den Kellner warten: Eine neue App macht's möglich!"
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.falstaff.at/nd/neue-app-fuer-kuerzere-wartezeiten-im-restaurant/"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/Falstaff_final-c.png'
              }
              width={80}
              altTag="Neue App für kürzere Wartezeiten im Restaurant"
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.pressreader.com/austria/kurier-3402/20190806/281595242153360"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={'https://image.getsby.at/general/press/kurier-c.png'}
              width={80}
              altTag={`Handy ersetzt "Herr Ober!"`}
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://futurezone.at/start-ups/mit-dieser-app-muss-man-nie-mehr-im-lokal-auf-den-kellner-warten/400564376"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/futurezoneLogoC.png'
              }
              width={80}
              altTag="Mit dieser App muss man nie mehr im Lokal auf den Kellner warten"
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.hogapage.at/nachrichten/netzwelt/it-software/bestellungen-im-lokal-per-handy-ordern/"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/HogaPage-c.png'
              }
              width={80}
              altTag="Bestellungen im Lokal per Handy ordern"
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://de.sputniknews.com/technik/20190822325638376-getsby-app-stressfrei-restaurant-besuchen/"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/Sputnik_logo_c.png'
              }
              width={80}
              altTag="Nie wieder auf den Kellner warten: App aus Österreich nimmt
            Bestellungen auf"
              fm="png"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.meinbezirk.at/eisenstadt/c-lokales/kein-warten-auf-den-kellner-mehr_a3592545"
            className="flex items-center justify-center m-4"
          >
            <Picture
              className="w-20"
              imageSource={
                'https://image.getsby.at/general/press/Bezirksblätter-c.png'
              }
              width={80}
              altTag="Kein Warten auf den Kellner mehr"
              fm="png"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PressBanner;
